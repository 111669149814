import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { initLocale } from './locales/appActions';
import Home from './page/home/home';
import Product from './page/product/product';
import NodeItem from './page/product/nodeItem';
import CarSolution from './page/solution/car';
import ChemistrySolution from './page/solution/chemistry';
import ElectricSolution from './page/solution/electric';
import SteelSolution from './page/solution/steel';
import TrackSolution from './page/solution/track';
import Cloud from './page/software/cloud';
import Edge from './page/software/edge';
import Eam from './page/software/eam';
import Stock from './page/software/stock';
import CtcItem from './page/software/ctcItem';
import Hardware2700 from './page/hardware/hardware2700';
import Hardware1900 from './page/hardware/hardware1900';
import Hardware3500 from './page/hardware/hardware3500';
import News from './page/news/news';
import NewsItem from './page/news/newsItem';
import About from './page/about/about';
import Agent from './page/agent/agent';
import ErrorPage from './page/error/404';
import Fengdian from './page/solution/fengdian';
import './App.css';
function App() {
  initLocale();
  return (
    <Router>
      <Routes>
        <Route path="/news" element={<News />} />
        <Route path="/newsItem" element={<NewsItem />} />
        <Route path="/about" element={<About />} />
        <Route path="/agent" element={<Agent />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/solution/car" element={<CarSolution />} />
        <Route path="/solution/chemistry" element={<ChemistrySolution />} />
        <Route path="/solution/electric" element={<ElectricSolution />} />
        <Route path="/solution/steel" element={<SteelSolution />} />
        <Route path="/solution/track" element={<TrackSolution />} />
        <Route path="/solution/feng" element={<Fengdian />} />
        <Route path="/product/cloud" element={<Cloud />} />
        <Route path="/product/edge" element={<Edge />} />
        <Route path="/product/eam" element={<Eam />} />
        <Route path="/product/stock" element={<Stock />} />
        <Route path="/product/ctc" element={<CtcItem />} />
        <Route path="/product/2700" element={<Hardware2700 />} />
        <Route path="/product/1900" element={<Hardware1900 />} />
        <Route path="/product/3500" element={<Hardware3500 />} />
        <Route path="/product/node" element={<Product />} />
        <Route path="/node/item" element={<NodeItem />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
