/**
 * @description 客户
 */
import React, { memo } from 'react';
// import customer1 from '../img/zs_tp6.jpg';
// import customer2 from '../img/zs_tp7.jpg';
// import customer3 from '../img/zs_tp8.jpg';

import './customer.css';
const Customer = memo((props) => {
  return (
    <div className="section">
      <div className="customer-img">
        <h1 className="section-title">典型客户</h1>
        {/* <img src={customer1} /> */}
        {/* <img src={customer2} />
        <img src={customer3} /> */}
        {/* <img src={customer4} />
                <img src={customer5} /> */}
      </div>
    </div>
  );
});
export default Customer;
