/**
 * @description 产品
 */
import React, { memo, useCallback, useState, useMemo } from 'react';
import qiche6 from '../../img/qiche6.jpg';
import data1900 from '../../img/product/data1900.png';
import data2700 from '../../img/data2700.png';
import sensor from '../../img/product/node.png';
import qiche1 from '../../img/homeq13.jpg';
import qiche2 from '../../img/qiche2.jpg';
import qiche3 from '../../img/qiche3.jpg';
import qiche4 from '../../img/qiche4.jpg';
import qiche5 from '../../img/qiche5.jpg';
import qiche_jg from '../../img/qiche_jg.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import Customer from '../../component/customer.jsx';
import { Layout, Tabs } from 'antd';
import './solution.css';
const { Content } = Layout;
const { TabPane } = Tabs;

const TrackSolution = memo((props) => {
  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ height: '100%' }}>
        <TopMenu />
        <div className="subpage">
          <header className="car-solution-header">
            <h5>汽车制造解决方案</h5>
            <p>
              凯奥思工业互联网平台，包含联接管理平台、工业智能体、工业应用平台三大部分。定位于做企业增量的智能决策系统，实现业务在云上敏捷开发，边缘可信运行。赋能行业合作伙伴深耕工业核心业务流，持续释放潜在业务价值。
            </p>
          </header>
          <div className="section">
            <div className="solution-section">
              <h1 className="section-title">案例背景</h1>
              <img src={qiche1} />
              <ul>
                <li>
                  <span>
                    风机作为轨道交通中重要大型设备，既承担气体交换保证舒适环境的作用。在发生火灾、有害气体泄漏等事故时，保障人民生命安全。
                  </span>
                </li>
                <li>
                  <span>
                    据统计近年来117件重大事故中，火灾事故56件，比重达48%。若对风机状态实时监测，并在此基础上深入分析与故障诊断，为潜在故障预示和演化、可靠性预测和制定维修策略提供技术支持，从而保证风机在其全寿命周期中安全。
                  </span>
                </li>
                <li>
                  <span>
                    轨道交通的各类风机多属于高耗能设备，对其有效的能效监测和管理，也是轨道交通绿色环保运营的要求。
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="interlace-section">
            <div className="solution-second-section">
              <h1 className="section-title">解决方案</h1>
              <ul>
                <li>
                  <img src={qiche2} />
                  <p>基于FPGA的Data Watch边缘端振动、温度、风量、电量等数据采集、传输。</p>
                </li>
                <li>
                  <img src={qiche3} />
                  <p>服务器端或云端对数据处理、存储，并通过故障专家系统进行分析。</p>
                </li>
                <li>
                  <img src={qiche4} />
                  <p>PHM预测性运维管理系统，对设备进行管理。</p>
                </li>
                <li>
                  <img src={qiche5} />
                  <p>
                    通过监控中心的数人小组,即可对全集团的核心设备数据进行分析,对故障进行诊断和预测。
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="section">
            <div className="solution-third-section">
              <h1 className="section-title">方案架构</h1>
              <img src={qiche_jg} />
            </div>
          </div>
          <div className="interlace-section">
            <div className="four-interlace-section">
              <h1 className="section-title">产品支撑</h1>
              <Tabs type="card" tabPosition="left">
                <TabPane tab="工业智能边缘终端3500" key="1">
                  <div className="img">
                    <img src={qiche6} />
                  </div>
                  <ul className="ul">
                    <li>
                      <h4>DATA WATCH 3500</h4>
                    </li>
                    <li>产品特点: 高性能 大通道数 灵活配置</li>
                    <li>产品用途: 大型旋转或往复式核心动设备</li>
                    <li>典型场景: 大中型空分设备汽轮机透平机械大中型水轮机发电机组等</li>
                  </ul>
                </TabPane>
                <TabPane tab="工业智能边缘终端2700" key="2">
                  <div className="img">
                    <img src={data2700} />
                  </div>
                  <ul className="ul">
                    <li>
                      <h4>DATA WATCH 2700</h4>
                    </li>
                    <li>产品特点: 高性价比、紧凑型、灵活配置</li>
                    <li>产品用途: 重要辅机动设备</li>
                    <li>
                      典型场景:
                      多轴联动机床，冷却塔风扇泵；风机；鼓风机；马达；磨煤机；离心机；混合器；搅拌机；空压机；中型往复式压缩机
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="工业智能边缘终端1900" key="3">
                  {/* <div className='img'><img src={data1900} /></div> */}
                  <ul className="ul">
                    <li>
                      <h4>DATA WATCH 1900</h4>
                    </li>
                    <li>产品特点: 高性价比、紧凑型、固定通道</li>
                    <li>产品用途: 一般用途的旋转、往复动设备</li>
                    <li>
                      典型场景:
                      中高端机床，加工中心，泵机、小型电动机、粉碎机、小型旋转式或往复式压缩机，小型水力涡轮，离心机
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="工业智能传感器" key="4">
                  <div className="img">
                    <img src={sensor} />
                  </div>
                  <ul className="ul">
                    <li>
                      <h4>Chaos Data Node</h4>
                    </li>
                    <li>产品特点: Wifi+Zigbee无线传输、三年以上寿命</li>
                    <li>产品用途: 工业现场不适宜布置传感器线缆</li>
                    <li>典型场景: 钢铁和汽车制造行业生产流水线上电机减速机轴承座等</li>
                  </ul>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <Customer />
          <Footer />
        </div>
      </Content>
    </Layout>
  );
});
export default TrackSolution;
