/**
 * @description 产品
 */
import React, { memo, useCallback, useState, useMemo } from 'react';
import qiche6 from '../../img/qiche6.jpg';
import data1900 from '../../img/product/data1900.png';
import data2700 from '../../img/data2700.png';
import sensor from '../../img/product/node.png';
import qiche1 from '../../img/homeq13.jpg';
import qiche2 from '../../img/qiche2.jpg';
import qiche3 from '../../img/qiche3.jpg';
import qiche4 from '../../img/qiche4.jpg';
import qiche5 from '../../img/qiche5.jpg';
import qiche_jg from '../../img/qiche_jg.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import Customer from '../../component/customer.jsx';
import { Layout, Tabs } from 'antd';
import './solution.css';
const { Content } = Layout;
const { TabPane } = Tabs;

const TrackSolution = memo((props) => {
  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ height: '100%' }}>
        <TopMenu />
        <div className="subpage">
          <header className="car-solution-header">
            <h5>风电机组声纹智能监测</h5>
            <p>
              凯奥思工业互联网平台，涵盖联接管理平台、工业智能体、工业应用平台三大核心部分。致力于成为企业增值的智能决策系统，推动业务在云端的敏捷开发，确保边缘端的可信运行。助力行业伙伴深入挖掘工业核心业务流，持续释放潜在业务价值。
            </p>
          </header>
          <div className="section">
            <div className="solution-section">
              <h1 className="section-title">案例背景</h1>
              <img src={qiche1} />
              <ul>
                <li>
                  <span>
                    传统风电运维主要依赖人工巡检，巡检效率低下且成本高昂，耗费大量人力和时间，同时人工判断故障主观性强，准确性难以保证。
                  </span>
                </li>
                <li>
                  <span>
                    风电机组关键部位故障发生时，缺乏有效的实时监测手段，难以快速精准定位故障原因，无法及时采取有效措施，导致停机时间延长，给企业带来巨大经济损失。
                  </span>
                </li>
                <li>
                  <span>
                    随着风电行业规模不断扩大，对风电机组运行稳定性和可靠性的要求日益提高，迫切需要智能化、高效的监测解决方案。
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="interlace-section">
            <div className="solution-second-section">
              <h1 className="section-title">解决方案</h1>
              <ul>
                <li>
                  <img src={qiche2} />
                  <p>智能硬件部署</p>
                </li>
                <li>
                  <img src={qiche3} />
                  <p>数据传输与存储</p>
                </li>
                <li>
                  <img src={qiche4} />
                  <p>智能监测与诊断平台</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="section">
            <div className="solution-third-section">
              <h1 className="section-title">方案架构</h1>
              <img src={qiche_jg} />
            </div>
          </div>
          <div className="interlace-section">
            <div className="four-interlace-section">
              <h1 className="section-title">产品支撑</h1>
              <Tabs type="card" tabPosition="left">
                <TabPane tab="高精度声纹传感器" key="1">
                  <div className="img"></div>
                  <ul className="ul">
                    <li>
                      <h4>高精度声纹传感器</h4>
                    </li>
                    <li>产品特点: 具有高灵敏度、高分辨率、宽频响范围等特点</li>
                    <li>能够准确捕捉微弱的声纹信号，适应复杂的风电运行环境</li>
                  </ul>
                </TabPane>
                <TabPane tab="智能数据采集终端" key="2">
                  <div className="img">
                    <img src={data2700} />
                  </div>
                  <ul className="ul">
                    <li>
                      <h4>智能数据采集终端</h4>
                    </li>
                    <li>产品特点: 具备强大的数据处理能力</li>
                    <li>
                      支持多通道数据采集、实时数据压缩和加密传输，确保数据的高效采集和安全传输。
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="风电声纹智能监测软件平台" key="3">
                  <div className="img"></div>
                  <ul className="ul">
                    <li>
                      <h4>风电声纹智能监测软件平台</h4>
                    </li>
                    <li>产品特点: 集成先进的声纹分析算法和人工智能模型</li>

                    <li>
                      具备智能诊断、精准预警、历史数据查询和分析等功能，为风电运维提供全面支持
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="工业智能传感器" key="4">
                  <div className="img"></div>
                  <ul className="ul">
                    <li>
                      <h4>Chaos Data Node</h4>
                    </li>
                    <li>产品特点: Wifi+Zigbee无线传输、三年以上寿命</li>
                    <li>产品用途: 工业现场不适宜布置传感器线缆</li>
                    <li>典型场景: 钢铁和汽车制造行业生产流水线上电机减速机轴承座等</li>
                  </ul>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <Customer />
          <Footer />
        </div>
      </Content>
    </Layout>
  );
});
export default TrackSolution;
