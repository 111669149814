/**
 * @description 软件产品Stock
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import second1 from '../../img/ruanj2.jpg';
import second2 from '../../img/ruanj3.jpg';
import second3 from '../../img/ruanj4.jpg';
import second4 from '../../img/ruanj5.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./software.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const Stock = memo((props) => {

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='software-header'>
					<h5>工业智能软件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='one-section'>
					<div className='software-section'>
						<ul className='top'>
							<li className='stock'></li>
							<li>
								<h1>库存优化管理系统</h1>
								<p>简化库存计划，并将各种复杂因素纳入计划，例如供应链细分、季节性、多库存位，以及更多的产品系列和更短的生命周期。</p>
                                <p>该解决方案集成了整个组织的数据，可实现真正的供应链可视化。在不同的计划层级上提供优化和决策支持，保证最佳的库存水平、利润、生产率和客户满意度。</p>
                            </li>
						</ul>
						<ul className='cloud-bottom'>
							<li>
								<img src={second1} />
								<p>实时查看库存数据。当库存水平不足时即刻发出警报，以便采取必要的应对措施</p> 
							</li>
							<li>
								<img src={second2} />
								<p>确定成品与半成品、零部件与原材料的最佳组合，为按库存生产、按订单加工和按订单制造制定对应的正确策略。</p> 
							</li>
							<li>
								<img src={second3} />
								<p>根据需求的不确定性和供应的变化，计算准确的安全库存水平。</p> 
							</li>
						</ul>
					</div>
				</div>
				<div className='software-customer-img'>
					<h1 className='section-title'>典型客户</h1>
					<img src={customer1} />
					<img src={customer2} />
					<img src={customer3} />
					<img src={customer4} />
					<img src={customer5} />
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Stock);
