/**
 * @description 软件产品Edge
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import second1 from '../../img/ruanj2.jpg';
import second2 from '../../img/ruanj3.jpg';
import second3 from '../../img/ruanj4.jpg';
import second4 from '../../img/ruanj5.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./software.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const Edge = memo((props) => {

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='software-header'>
					<h5>工业智能软件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='one-section'>
					<div className='software-section'>
						<ul className='top'>
							<li className='edge'></li>
							<li>
								<h1>边缘计算与存储终端 Chaos Edge</h1>
								<p>本地分析系统是一个工业级，服务中心于工业现场对设备运行数据采集、传输、处理、存储、预警、报警、计算分析，为设备故障诊断和异常排查提供服务中心，通过数据和智能学习算法能够进行设备故障的预测性诊断，部署简单，无需专业IT团队即可快速接入。</p>
							</li>
						</ul>
						<ul className='bottom'>
							<li>
								<img src={second1} />
								<p>支持Linux与Windows 平台部署</p> 
							</li>
							<li>
								<img src={second2} />
								<p>支持企业私有云部署， 扩容方便</p> 
							</li>
							<li>
								<img src={second3} />
								<p>支持Modbus/MQTT/OPC UA 接入第三方数据</p> 
							</li>
							<li>
								<img  src={second4}/>
								<p>所有数据可通过OPC UA方式 访问获取</p> 
							</li>
						</ul>
					</div>
				</div>
				<div className='software-customer-img'>
					<h1 className='section-title'>典型客户</h1>
					<img src={customer1} />
					<img src={customer2} />
					<img src={customer3} />
					<img src={customer4} />
					<img src={customer5} />
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Edge);
