/**
 * @description CTC传感器详情
 */
import React, { memo, useCallback, useState, useEffect } from 'react';
import { axiosInstance, baseUrl } from '../../utils/axiosInstance';
import { Link } from 'react-router-dom';
import ta102 from '../../img/ta102.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout } from 'antd';
import '../product/nodeItem.css';
const { Content } = Layout;

const CtcItem = memo((props) => {
  const [index, setIndex] = useState(5);
  const [newList, setNewList] = useState([]);
  useEffect(() => {
    axiosInstance.get(`/product/page/1/100/CTC`).then((res) => {
      if (res.data.success) {
        setNewList(res.data.content);
      } else {
        setNewList([]);
      }
    });
  }, [index]);

  const onChange = useCallback((val) => {
    setIndex(val);
  }, []);

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ height: '100%' }}>
        <TopMenu />
        <div className="subpage">
          <header className="product-header">
            <h5>国际CTC 传感器</h5>
            <p>
              国际CTC公司（Connection Technology Center,
              Inc.）是一家致力于提供高品质设备振动监测与保护产品的高科技公司，其产品涵盖加速度传感器、速度传感器、电涡流振动探头、线缆、连接器、接线箱、信号调理器、振动仪表/继电器以及相关安装测试配件等。
            </p>
          </header>
          <div className="section">
            <div className="prouct-first-section">
              <h1 className="section-title">详细介绍</h1>
              <p className="introduce-p">
                <strong>国际CTC公司（Connection Technology Center, Inc.）</strong>
                是一家致力于提供多类型高品质的设备振动监测和保护产品的高科技公司，其产品涵盖加速度传感器、速度传感器、电涡流振动探头、高品质线缆、接线箱、信号调理器、振动仪表/继电器以及相关安装测试配件等。
                CTC公司是国际联邦振动协会理事单位，凭借安全可靠的硬件性能，十多年的行业经验和无与伦比的质
              </p>
              <p className="introduce-p">
                CTC公司是国际联邦振动协会理事单位，凭借安全可靠的硬件性能，十多年的行业经验和无与伦比的质保承诺，目前已成为全球三大振动传感器提供商之一，产品已广泛应用于机械制造、水利水电、石油化工、造纸、风电、工矿、铁路、冶金、水泥、食品加工等需要对设备进行振动监控的工业现场和危险区域。
              </p>
              <p className="introduce-p">
                经过十多年的潜心研发，CTC公司设计出了目前世界上最可靠耐用的振动分析硬件设备，同时拥有行业领先的无条件终生质保服务。
              </p>
              <p className="introduce-p">
                CTC拥有一整套完整的剪切式振动传感器生产线，并采用先进PZT陶瓷材料及密封玻璃全焊接结构，激光焊接技术，使得产品噪声低，可靠性高，可用于潮湿、腐蚀、甚至深水等各种恶劣环境。产品通过了ISO
                9001:2008认证，可提供NIST可溯源标定证书。
              </p>
              <ul className="introduce-ul">
                <li>
                  <h5>高分辨能力</h5>
                  <p>
                    CTC振动传感器极低的噪声与积分电 路使得产品对小幅值的振动信号具有
                    良好的分辨能力，同时能够清晰、完 成的测量和监测低频振动信号
                  </p>
                </li>
                <li>
                  <h5>精密剪切模式传感结构</h5>
                  <p>CTC振动传感器采用的精密PZT陶 瓷剪切模式传感结构确保了准确无干 扰的振动测量</p>
                </li>
                <li>
                  <h5>双屏蔽结构</h5>
                  <p>
                    CTC振动传感器采用的双屏蔽结构将测量和监测过程中电磁干扰（EMI）和射频干扰（RFI）对振动信号的影响降到最低
                  </p>
                </li>
                <li>
                  <h5>全不锈钢焊接技术</h5>
                  <p>CTC振动传感器坚固的不锈钢外壳和焊接技术确保能够适应您的工厂环境</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="interlace-section">
            <div className="prouct-second-section">
              <h1 className="section-title">产品展示</h1>
              <ul>
                {newList.length > 0
                  ? newList.map((item, index) => {
                      return (
                        <Link
                          to="/node/item"
                          key={item.id}
                          onClick={() => {
                            console.log(item);
                            localStorage.setItem('nodeItem', JSON.stringify(item));
                          }}
                        >
                          <li>
                            <img src={baseUrl + item.imgPath} />
                            <h5>{item.name}</h5>
                            <p>{item.description}</p>
                          </li>
                        </Link>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
          <Footer />
        </div>
      </Content>
    </Layout>
  );
});
export default CtcItem;
